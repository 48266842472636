import StudentPaymentReceipt from "components/Reports/StudentPaymentReceipt";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import "../../../assets/css/class-assistant-payments.css";
import { getLastThreeMonthsFeeByClassAssistants } from "actions/ClassAssistantLayout";
import dateFormat, { masks } from "dateformat";
import { updateStudentPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import { getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { useReactToPrint } from "react-to-print";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import { getUser } from "actions/Auth";
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";
import { updateStudentBulkPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import StudentBulkPaymentReceipt from "components/Reports/StudentBulkPaymentReceipt";
import { getAttendanceCountByClassAssistants } from "actions/ClassAssistantLayout";
import StudentImage from "../../../assets/img/brand/blank_profile_pic.png";

const ClassAssistantStudentPayments = () => {
  const receiptRef = useRef();
  const bulkReceiptRef = useRef();
  const barcodeInputRef = useRef(null);

  const [selectedTwoMonthsAgo, setSelectedTwoMonthsAgo] = useState(null);
  const [selectedLastMonth, setSelectedLastMonth] = useState(null);
  const [selectedThisMonth, setSelectedThisMonth] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [months, setMonths] = useState([]);
  const [studentClasses, setStudentClasses] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [barcodeInput, setBarcodeInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const [selectedFeeType, setSelectedFeeType] = useState({});
  const [student, setStudent] = useState({});
  const [receiptData, setReceiptData] = useState({
    subject: "",
    grade: "",
    year: "",
    teacherName: "",
    invoiceNo: "",
    invoiceDate: "",
    paidMoth: "",
    paidAmount: "",
    studentName: "",
    barcode: "",
    payType: "",
  });

  const [bulkReceiptData, setBulkReceiptData] = useState({
    invoiceNo: "",
    invoiceDate: "",
    studentName: "",
    barcode: "",
    payments: [
      {
        subject: "",
        grade: "",
        year: "",
        teacherName: "",
        paidMonth: "",
        paidAmount: "",
        payType: ""
      }
    ]
  });

  const fetchData = async () => {
    const response = await getUser();
    setUserId(response.id);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    let newDate = `${year}-${month}`;
    let prevMonth = month - 1;
    let twoMonthsAgo = month - 2;

    if (month === 1) {
      prevMonth = `${year - 1}-12`;
      twoMonthsAgo = `${year - 1}-11`;
    } else if (month === 2) {
      prevMonth = `${year}-1`;
      twoMonthsAgo = `${year - 1}-12`;
    } else {
      prevMonth = `${year}-${prevMonth}`;
      twoMonthsAgo = `${year}-${twoMonthsAgo}`;
    }
    setMonths([twoMonthsAgo, prevMonth, newDate]);
  };

  const groupByTeacherGradeSubject = async (data) => {
    const grouped = data.reduce((acc, item) => {
      const { classUserId, teacher, subject, grade, price, user_fee_type } =
        item;
      const key = `${classUserId}`;

      if (!acc[key]) {
        acc[key] = {
          teacherName: teacher,
          subjectName: subject,
          gradeName: grade,
          classFee: price,
          feeType: user_fee_type,
          items: [],
        };
      }

      acc[key].items.push(item);
      return acc;
    }, {});
    const result = Object.keys(grouped).map((key) => ({
      classUserId: key,
      teacherName: grouped[key].teacherName,
      subjectName: grouped[key].subjectName,
      gradeName: grouped[key].gradeName,
      classFee: grouped[key].classFee,
      feeType: grouped[key].feeType,
      items: grouped[key].items,
    }));
    setGroupedData(result);
  };

  let barcodeString = "";

  const handleKeyDown = async (event) => {
    const barcodePattern = /^[0-9]$/;
    if (barcodePattern.test(event.key)) {
      barcodeString += event.key;
      if (barcodeString.length === 7) {
        barcodeInputRef.current.value = barcodeString;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isInactive, setInactive] = useState(false);
  const [inactiveMsg, setInactiveMsg] = useState(null);

  const getStudentPay = async (barcode) => {
    if (barcode.length <= 7) {
      setBarcodeInput(barcode);
    } else if (barcode.length > 7) {
      barcode = barcode.slice(7);
      setBarcodeInput(barcode);
    }
    setBarcode(barcode);
    try {
      if (barcode.length === 7) {
        const studentDetails = await getStudentByClassAssistants(barcode);
        if (studentDetails.success === true) {
          setInactive(false);
          setInactiveMsg(null);
          setStudent(studentDetails.students);
          const response = await getLastThreeMonthsFeeByClassAssistants({
            barcode: barcode,
            months: months,
          });
          if (response.length > 0) {
            setNullMessage(false);
            setStudentClasses(response);
            setTotalAmount(0);
            await groupByTeacherGradeSubject(response);
          } else {
            setStudentClasses(null);
            setNullMessage(true);
            setTotalAmount(0);
          }
        } else {
          setNullMessage(false);
          setInactive(true);
          setInactiveMsg('This student is not active.')
        }
      } else {
        setStudentClasses(null);

      }
    } catch (error) {
      setStudentClasses(null);
    }
  };

  const handleInputChange = async (event) => {
    setTotalAmount(0);
    setBulkPayments([]);
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      setBarcodeInput(value);
      if (name === "barcode") {
        await getStudentPay(value);
      }
    }
  };

  const resetSelections = () => {
    setNullMessage(false);
    setSelectedTwoMonthsAgo(null);
    setSelectedLastMonth(null);
    setSelectedThisMonth(null);
    setStudentClasses(null);
    setGroupedData([]);
    setStudent({ registration_number: "" });
    setBarcodeInput("");
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const handleBulkPrint = useReactToPrint({
    content: () => bulkReceiptRef.current,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedClass, setSelectedClass] = useState(null);

  const [classFees, setClassFees] = useState({
    update_class_fees: []
  });

  const [isBulkPay, setBulkPay] = useState(false);

  const makeAPayment = async (details) => {
    try {
      setBulkPay(false);
      if (details.amount !== null) {
        setSelectedClass(details);
      } else {
        const currentDate = new Date();
        const previousMonthDate = new Date(currentDate);
        const month = currentDate.getMonth() + 1;
        const todayDate = String(currentDate.getDate()).padStart(2, "0");
        let preMonth = new Date(currentDate);
        if (month !== details.month) {
          preMonth = new Date(details.year, details.month - 1, 15);
        } else {
          preMonth = new Date(details.year, details.month - 1, todayDate);
        }
        const formatedDate = dateFormat(preMonth, "yyyy-mm-dd h:MM:ss");

        let typeVar;
        if (Object.keys(selectedFeeType).length !== 0) {
          const [[key, value]] = Object.entries(selectedFeeType);

          if (key === `${details.classUserId}-${details.month}`) {
            typeVar = value;
          } else {
            typeVar = details.user_fee_type;
          }
        } else {
          typeVar = details.user_fee_type;
        }

        const payDetails = {
          month: details.month,
          year: details.year,
          classUserId: +details.classUserId,
          paymentType: details.payment_type,
          feeType: typeVar,
          date: formatedDate,
          paid_date: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
          userId: +userId,
        };
        setClassFees((prevState) => ({
          ...prevState,
          update_class_fees: [...prevState.update_class_fees, payDetails]
        }));
        const response = await updateStudentPaymentByClassAssistant(payDetails);
        setReceiptData({
          teacherName: details.teacher,
          subject: details.subject,
          grade: details.grade,
          year: details.year,
          month: details.month,
          barcode: student.barcode,
          studentName: student.full_name,
          invoiceNo: response.data.invoice_number,
          paidAmount: response.data.amount,
          invoiceDate: currentDate,
          payType: response.data.fee_type,
        });
        if (response.success === true) {
          await getStudentPay(barcode);
          handlePrint();
        }
      }
    } catch (error) {
      console.error("Payment error: ", error);
    }
  };

  const [visibleOptionIndex, setVisibleOptionIndex] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);

  const handleInputChange1 = async (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentListByAssistant(searchTerm);
      const filteredItems = response.data.data.filter((user) =>
        user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filteredItems);
    }
  };

  const [nullMessage, setNullMessage] = useState(false);
  const getStudentBySeacrh = async (student) => {
    try {
      setNullMessage(false);
      setFilteredUsers(null);
      const studentDetails = await getStudentByClassAssistants(student);
      setStudent(studentDetails.students);
      setBarcodeInput(student);
      const response = await getLastThreeMonthsFeeByClassAssistants({
        barcode: student,
        months: months,
      });
      if (response.length > 0) {
        setStudentClasses(response);
        setTotalAmount(0);
        setBulkPayments([]);
        await groupByTeacherGradeSubject(response);
      } else {
        setNullMessage(true);
        setStudentClasses(null);
        setTotalAmount(0);
        setBulkPayments([]);
      }
    } catch (error) { }
  };

  const [searchbar, setSearchBar] = useState(false);
  const [bulkPayments, setBulkPayments] = useState([]);

  const handleRadioChange = (key, feeType) => {
    setSelectedFeeType((prev) => ({
      ...prev,
      [key]: feeType
    }));

    // Update fee type in bulk payment if the class is already added
    setBulkPayments((prev) => {
      const updatedPayments = prev.map((item) =>
        item.key === key ? { ...item, feeType } : item
      );
      calculateTotalAmount(updatedPayments); // Recalculate total
      return updatedPayments;
    });
  };

  // Function to calculate the total amount
  const calculateTotalAmount = (payments) => {
    const total = payments.reduce((sum, item) => {
      const feeType = item.feeType;
      const classFee = item.classDetails.price;
      if (feeType === "FULL") {
        return sum + classFee;
      } else if (feeType === "HALF_FREE") {
        return sum + (classFee * 0.5);
      } else {
        return sum; // FULL_FREE means add 0
      }
    }, 0);

    setTotalAmount(total); // Update the total amount
  };

  // Modified addAPayment function to recalculate the total amount
  const addAPayment = (classDetails, isChecked) => {
    const key = `${classDetails.classUserId}-${classDetails.month}`;

    if (isChecked) {
      // Add class to bulk payment
      setBulkPayments((prev) => {
        const updatedPayments = [
          ...prev,
          { key, classDetails, feeType: selectedFeeType[key] || "FULL" }
        ];
        calculateTotalAmount(updatedPayments); // Recalculate total
        return updatedPayments;
      });
    } else {
      // Remove class from bulk payment
      setBulkPayments((prev) => {
        const updatedPayments = prev.filter(item => item.key !== key);
        calculateTotalAmount(updatedPayments); // Recalculate total
        return updatedPayments;
      });
    }
  };

  // Function to handle bulk payment submission
  const submitBulkPayment = async () => {
    try {
      setBulkPay(true);
      // Process bulkPayments array
      const formatedDate = dateFormat(new Date(), "yyyy-mm-dd h:MM:ss");
      const currentDate = new Date();
      const updateClassFees = bulkPayments.map(details => ({
        classUserId: details.classDetails.classUserId,
        month: details.classDetails.month,
        year: details.classDetails.year,
        feeType: details.feeType,
        date: formatedDate, // use formatted date for submission
        paid_date: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
        userId: +userId, // replace with actual user ID
        paymentType: details.classDetails.payment_type // assuming payment_type exists in classDetails
      }));

      // Create the final API payload
      const payload = {
        update_class_fees: updateClassFees
      };
      const response = await updateStudentBulkPaymentByClassAssistant(payload);
      setBulkReceiptData({
        barcode: student.barcode,
        studentName: student.full_name,
        payments: response.class_fees.map((details) => ({
          teacherName: details.class_user[0].teacher_class.teacher.full_name || 'N/A',
          subject: details.class_user[0].teacher_class.subject.name || 'N/A',
          grade: details.class_user[0].teacher_class.grade.name,
          year: details.class_fee.year,
          month: details.class_fee.month,
          paidAmount: details.class_fee.amount,
          invoiceDate: currentDate,
          payType: details.class_fee.fee_type,
          invoiceNo: details.class_fee.invoice_number,
        }))
      });
      if (response.success === true) {
        await getStudentPay(barcode);
        setTotalAmount(0);
        setBulkPayments([]);
        handleBulkPrint();
      } else {
        setTotalAmount(0);
        setBulkPayments([]);
      }
    } catch (error) {

    }
  };

  const [isPopover, setPopover] = useState(false);
  const [attendance, setAttendance] = useState([]);

  const getAttendanceCount = async (details) => {
    try{
      const response = await getAttendanceCountByClassAssistants(details[0], details[1]);
      if(response.length > 0) {
        setPopover(true);
        setAttendance(response);
      } else {
        setPopover(false);
      }
    } catch(error) {
      setPopover(false);
    }
  }

  return (
    <>
      <div className="mt--3 container-fluid assistant-container">
        <Card className="mt-4 mb-4 pb-4 shadow">
          <CardHeader className="border-0 assistantStudentHeader">
            <div
              className="headerLeft"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 className="mb-0 mr-2">Student Payments</h3>
              <i
                class="fa-solid fa-magnifying-glass"
                style={{ cursor: "pointer" }}
                onClick={() => setSearchBar(!searchbar)}
              ></i>
            </div>
          </CardHeader>
          {searchbar ? (
            <div className="ml-2">
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange1}
                    placeholder="Search..."
                    style={{
                      borderBottomLeftRadius: searchItem !== "" ? "0px" : "",
                      borderBottomRightRadius: searchItem !== "" ? "0px" : "",
                    }}
                  />
                  {searchItem && (
                    <ul
                      style={{
                        position: "absolute",
                        width: "94%",
                        listStyleType: "none",
                        padding: "0",
                        border: "1px solid #ddd",
                        zIndex: 10,
                        backgroundColor: "white",
                        display: filteredUsers === null ? "none" : "",
                      }}
                    >
                      {filteredUsers?.map((option, index) => (
                        <li
                          key={index}
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                            cursor: "pointer",
                          }}
                          onClick={() => getStudentBySeacrh(option.barcode)}
                        >
                          {option.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
              </Col>
            </div>
          ) : null}
          <div className="ml-4 barcodeSearch">
            <FormGroup>
              <Input
                id="barcode"
                placeholder="Barcode"
                type="text"
                value={barcodeInput}
                onChange={handleInputChange}
                ref={barcodeInputRef}
                name="barcode"
                pattern="\d*"
                className="barcodeSearchInput"
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="student_id"
                placeholder="Student Id"
                type="text"
                value={student?.registration_number}
                name="student_id"
                disabled
                className="barcodeSearchInput"
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={resetSelections} color="primary">
                Reset
              </Button>
              {!isBulkPay ?
                <Button onClick={handlePrint} color="primary">
                  Last Receipt
                </Button> :
                <Button onClick={handleBulkPrint} color="primary">
                  Last Receipt
                </Button>
              }
            </FormGroup>
          </div>
          {nullMessage ?
            <div style={{ paddingLeft: "20px" }}>
              {student.full_name} has not enrolled to any class yet!
            </div>
            : null}
          <div className="classList">
            <div className="bodySection">
              <div className="classPaymentOptions">
                {studentClasses !== null ? (
                  groupedData.map((studentClass, classIndex) => (
                    <Form key={classIndex} className="ml-4 mb-2 assistantStudentPayments">
                      <div className="classDetails">
                        <h3>{studentClass.teacherName}</h3>
                        <p>{studentClass.subjectName} - {studentClass.gradeName}</p>
                        <p>Rs.{studentClass.classFee}.00</p>
                        <p style={{ color: "green" }}>{studentClass.feeType}</p>
                      </div>
                      <div className="payHistory">
                        {studentClass.items.map((classes, index) => {
                          const key = `${classes.classUserId}-${classes.month}`;

                          return (
                            <div className="paymentOptions" key={index}>
                              <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                <Label style={{ fontWeight: "bold" }}> 
                                  {/* <i className="fa-solid fa-calendar-days mr-2" id="PopoverLegacy"
                                    type="button" onClick={() => getAttendanceCount([studentClass.classUserId, (classes.year+ '-' + classes.month.toString().padStart(2, "0"))])}></i>                                                             */}
                                  {classes.year}-{classes.month.toString().padStart(2, "0")}
                                  </Label>
                                  {/* <UncontrolledPopover
                                  placement="right"
                                  target="PopoverLegacy"
                                  trigger="legacy"
                                >
                                  <PopoverBody>
                                    <h4>
                                    Dates Attended
                                    </h4>
                                    {isPopover?
                                    attendance?.map((att) => (
                                      <li  key={att.id} className="ml-2">{(att.date).split(' ')[0]}</li>
                                    )) : 'None'}
                                  </PopoverBody>
                                </UncontrolledPopover> */}
                                {classes.amount === null && (
                                  <Input
                                    type="checkbox"
                                    className="ml-2 large-checkbox"
                                    checked={bulkPayments.some(item => item.key === key)} // ensure checkbox reflects the state
                                    onChange={(e) => addAPayment(classes, e.target.checked)}
                                    style={{ position: 'relative' }}
                                  />
                                )}
                                <div
                                  className="radioOptions"
                                  style={{ display: visibleOptionIndex === classIndex ? "" : "none" }}
                                >
                                  <FormGroup check>
                                    <Input
                                      name={key}
                                      type="radio"
                                      checked={
                                        selectedFeeType[key] === "FULL"
                                      }
                                      onChange={() => handleRadioChange(key, "FULL")}
                                      disabled={classes.amount !== null}
                                    />
                                    <Label check>Full</Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Input
                                      name={key}
                                      type="radio"
                                      checked={
                                        selectedFeeType[key] === "HALF_FREE"
                                      }
                                      onChange={() => handleRadioChange(key, "HALF_FREE")}
                                      disabled={classes.amount !== null}
                                    />
                                    <Label check>Half</Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Input
                                      name={key}
                                      type="radio"
                                      checked={
                                        selectedFeeType[key] === "FULL_FREE"
                                      }
                                      onChange={() => handleRadioChange(key, "FULL_FREE")}
                                      disabled={classes.amount !== null}
                                    />
                                    <Label check>Free</Label>
                                  </FormGroup>
                                </div>
                              </FormGroup>

                              <div className="paymentBtn">
                                <Button
                                  className={classes.amount !== null ? "paidBtn" : "unpaidBtn"}
                                  onClick={() => makeAPayment(classes)}
                                  disabled={classes.amount !== null}
                                >
                                  {classes.amount !== null ? `Paid` : `Pay`}
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="visiblePayOptions">
                        {visibleOptionIndex === classIndex ? (
                          <SlArrowDown onClick={() => setVisibleOptionIndex(null)} />
                        ) : (
                          <SlArrowUp onClick={() => setVisibleOptionIndex(classIndex)} />
                        )}
                      </div>
                    </Form>
                  ))
                ) : (
                  null
                )}
              </div>
              {studentClasses !== null ?
                <FormGroup className="mt-4">
                  <Row className="ml-4">
                    <Button onClick={submitBulkPayment} color="primary">
                      Bulk Payment
                    </Button>
                    <h3 className="mt-2">Total Amount: Rs.{totalAmount}.00</h3>
                  </Row>
                </FormGroup> : null}
            </div>
            {studentClasses !== null ? (
              <div className="studentSection">
                <div className="profile-card">
                  <div className="imageStudent">
                    <img src={student.image_url ? student.image_url : StudentImage} className="studentPaymentImage" />
                  </div>
                  <div className="studentDetailsClassAssistant">
                    <h3><i class="fa-solid fa-circle-user"></i> {student.full_name}</h3>
                    <p style={{ fontSize: "12px" }}><i class="fa-solid fa-registered"></i> {student.registration_number}</p>
                    {student.phone_number !== null ? <p><i class="fa-solid fa-phone-volume"></i> {student.phone_number}</p> : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Card>
        <div style={{ display: "none" }}>
          <StudentPaymentReceipt ref={receiptRef} allDetails={receiptData} />
        </div>
        <div style={{ display: "none" }}>
          <StudentBulkPaymentReceipt ref={bulkReceiptRef} allDetails={bulkReceiptData} />
        </div>
      </div>
    </>
  );
};

export default ClassAssistantStudentPayments;