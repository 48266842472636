import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
  Label,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { createStudent } from "actions/Students";
import { editStudent } from "actions/Students";
import { Link } from "react-router-dom";
import { getAllActiveGrades } from "actions/Grades";
import { findTeachersByGradeId } from "actions/Teachers";
import { findAllSubjectsByTeacherIdAndGradeId } from "actions/Subjects";
import html2canvas from 'html2canvas';
import { createRoot } from 'react-dom/client';
import { BiEdit } from "react-icons/bi";
import { LiaQrcodeSolid } from "react-icons/lia";

import "../../assets/css/qr-card-styles.css";
import "../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { getStudentsByLimit } from "actions/Students";
import { filterStudentList } from "actions/Students";
import StudentId from "components/StudentId/StudentId";
import StudentIdBack from "components/StudentId/StudentIdBack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from 'date-fns';

const Students = () => {
  const Status = {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    DEACTIVATED: "DEACTIVATED",
  };

  const Sex = {
    Male: "MALE",
    Female: "FEMALE",
  };

  const Syllabus = {
    Local: "LOCAL",
    London: "LONDON",
  };

  const Medium = {
    Sinhala: "SINHALA",
    English: "ENGLISH",
    Tamil: "TAMIL"
  };

  const Modes = {
    Sign_Board: "SIGN_BOARD",
    Leaflet: "LEAFLET",
    From_a_student_or_parent: "FROM_A_STUDENT_OR_PARENT"
  }

  const range = (start, end, step) => {
    let output = [];
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  }

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date();

  const [allStudents, setAllStudents] = useState(null);
  const [isAllStudents, setIsAllStudents] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [allGrades, setAllGrades] = useState(null);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [selectedGradeForStudent, setSelectedGradeForStudent] = useState(null);
  const [allTeachersByGrade, setAllTeachersByGrade] = useState(null);
  const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [allSubjects, setAllSubjects] = useState(null);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [gradeFilterDropdownOpen, setGradeFilterDropdownOpen] = useState(false);
  const [subjectFilterDropdownOpen, setSubjectFilterDropdownOpen] = useState(false);
  const [teacherFilterDropdownOpen, setTeacherFilterDropdownOpen] = useState(false);
  const [selectedGradeFilter, setSelectedGradeFilter] = useState(null);
  const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
  const [selectedTeacherFilter, setSelectedTeacherFilter] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [allActiveSubjects, setAllActiveSubjects] = useState(null);
  const [filterStudent, setFilterStudent] = useState(false);
  const [isFilterError, setFilterError] = useState(false);
  const [enrollClsError, setEnrollClsError] = useState('');
  const [birthDate, setBirthDate] = useState(new Date());
  const [sexDropdownOpen, setSexDropdownOpen] = useState(false);
  const [selectedSex, setSelectedSex] = useState(null);
  const [syllabusDropdownOpen, setSyllabusDropdownOpen] = useState(false);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [mediumDropdownOpen, setMediumDropdownOpen] = useState(false);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [modeDropdownOpen, setModeDropdownOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const [editedBirthDate, setEditedBirthDate] = useState(new Date());
  const [sexEditedDropdownOpen, setEditedSexDropdownOpen] = useState(false);
  const [selectedEditedSex, setEditedSelectedSex] = useState(null);
  const [syllabusEditedDropdownOpen, setEditedSyllabusDropdownOpen] = useState(false);
  const [selectedEditedSyllabus, setEditedSelectedSyllabus] = useState(null);
  const [mediumEditedDropdownOpen, setEditedMediumDropdownOpen] = useState(false);
  const [selectedEditedMedium, setEditedSelectedMedium] = useState(null);
  const [modeEditedDropdownOpen, setEditedModeDropdownOpen] = useState(false);
  const [selectedEditedMode, setEditedSelectedMode] = useState(null);
  const [addmisionDate, setAddmissionDate] = useState(new Date());

  const [searchQuery, setSearchQuery] = useState("");
  const [initialStudent, setInitialStudent] = useState({
    id: null,
    registration_number: "",
    full_name: "",
    phone_number: "",
    password: "",
    enrolled_classes: [],
    status: "",
  });

  const [isData, setIsData] = useState({
    full_name: "",
    enrolled_classes: []
  });

  const [isError, setIsError] = useState({
    full_name: "",
    email: "",
    phone_number: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
  });

  const getStudents = async (itemsPerPge, skip) => {
    const students = await getStudentsByLimit(itemsPerPge, skip);
    setPageCount(students.data.pageCount);
    setAllStudents(students.data.data);
    if (students.data.data.length > 0) {
      setIsAllStudents(true);
    }
  }

  const fetchData = async () => {
    try {
      await getStudents(itemsPerPge, skip);
      const grades = await getAllActiveGrades();
      setAllGrades(grades);
      setLoadingStudents(false);
    } catch (error) {
      setLoadingStudents(false);
      setIsAllStudents(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterStudents = async (gradeId, subjectId, teacherId) => {
    try {
      setFilterStudent(true);
      const searched = await filterStudentList(itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId);
      setPageCount(searched.data.pageCount);
      setCurrentStart(0);
      setCurrentPage(skip + 1);
      setAllStudents(searched.data.data);
    } catch (error) {
      setPageCount(0);
      setFilterError(true);
      setAllStudents(null);
    }
  }

  const filterBySearch = async (itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId) => {
    const searched = await filterStudentList(itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId);
    setCurrentPage(skip + 1);
    setPageCount(searched.data.pageCount);
    setAllStudents(searched.data.data);
  }

  const resetFilter = async () => {
    getStudents(itemsPerPge, skip);
    setFilterStudent(false);
    setSelectedGradeFilter(null);
    setSelectedSubjectFilter(null);
    setSelectedTeacherFilter(null);
    setAllTeachers(null);
    setAllActiveSubjects(null);
    setSearchQuery("");
    setCurrentStart(0);
    setCurrentPage(1);
  }
  const handlePagination = async (pageNumber) => {
    if (filterStudent) {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery, selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id);
    } else {
      await getStudents(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  const getTeachers = async (gradeId) => {
    const teachersByGrade = await findTeachersByGradeId(gradeId);
    setAllTeachersByGrade(teachersByGrade.filter(teacher => teacher.status = 'ACTIVE'));
  }

  const getSubjects = async (gradeId, teacherId) => {
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
    setAllSubjects(subjectsByGradeAndTeacher.filter(subject => subject.status = true));
  }

  const getTeachersToFilter = async (gradeId) => {
    const teachersByGrade = await findTeachersByGradeId(gradeId);
    setAllTeachers(teachersByGrade.filter(teacher => teacher.status = 'ACTIVE'));
  }

  const getSubjectsToFilter = async (gradeId, teacherId) => {
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
    setAllActiveSubjects(subjectsByGradeAndTeacher.filter(subject => subject.status = true));
  }
  const [selectedClasses, setSelectedClasses] = useState([]);

  const updateSelectedClasses = (grade, teacher, subject) => {
    setSelectedClasses(prevSelectedClasses => [
      ...prevSelectedClasses,
      { grade: grade, teacher: teacher, subject: subject }
    ]);
  };

  const removeSelectedClass = (indexToRemove) => {
    setSelectedClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addClass = async () => {
    if (selectedTeacher === null || selectedGradeForStudent === null || selectedSubject === null) {
      setEnrollClsError("Grade, Teacher, and Subject must be selected.")
    } else {
      const newEnrolledClass = {
        teacherId: selectedTeacher.id,
        gradeId: selectedGradeForStudent.id,
        subjectId: selectedSubject.id
      };

      setIsData(prevState => {
        const isAlreadyEnrolled = prevState.enrolled_classes.some(
          enrolledClass =>
            enrolledClass.teacherId === newEnrolledClass.teacherId &&
            enrolledClass.gradeId === newEnrolledClass.gradeId &&
            enrolledClass.subjectId === newEnrolledClass.subjectId
        );

        if (isAlreadyEnrolled) {
          setFailed(true);
          setErrorMessage('This student is already enrolled in this class.');
          return prevState;
        }

        const updatedEnrolledClasses = [...prevState.enrolled_classes, newEnrolledClass];
        return {
          ...prevState,
          enrolled_classes: updatedEnrolledClasses
        };
      });

      setSelectedClasses(prevSelectedClasses => {
        const isAlreadySelected = prevSelectedClasses.some(
          selectedClass =>
            selectedClass.grade === selectedGradeForStudent.name &&
            selectedClass.teacher === selectedTeacher.full_name &&
            selectedClass.subject === selectedSubject.name
        );
        if (!isAlreadySelected) {
          return [
            ...prevSelectedClasses,
            { grade: selectedGradeForStudent.name, teacher: selectedTeacher.full_name, subject: selectedSubject.name }
          ];
        }
        return prevSelectedClasses;
      });
    }
  };

  const removeEnrolledClass = (enrolledClassIdToRemove) => {
    setIsData(prevState => {
      const updatedEnrolledClasses = prevState.enrolled_classes.slice();
      updatedEnrolledClasses.splice(enrolledClassIdToRemove, 1);
      return {
        ...prevState,
        enrolled_classes: updatedEnrolledClasses
      };
    });
    removeSelectedClass(enrolledClassIdToRemove);

  };
  useEffect(() => {
  }, [isData]);

  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    setIsData({ ...isData, [event.target.name]: event.target.value });
  };

  const isValidStudent = (value) => {
    const errors = {};
    if (value.full_name.length < 3) {
      errors.full_name =
        "Student Full Name must be at least 3 characters long.";
    }
    if ((value.phone_number !== undefined && value.phone_number !== null)) {
      if (value.phone_number !== "") {
        if ((value.phone_number.length !== 10)) {
          errors.phone_number = "Value must be a valid phone number.";
        }
      }
    }
    if ((value.land_phone_number !== undefined && value.land_phone_number !== null)) {
      if (value.land_phone_number !== "") {
        if ((value.land_phone_number.length !== 10)) {
          errors.land_phone_number = "Value must be a valid phone number.";
        }
      }
    }
    if ((value.whatsapp_number !== undefined && value.whatsapp_number !== null)) {
      if (value.whatsapp_number !== "") {
        if ((value.whatsapp_number.length !== 10)) {
          errors.whatsapp_number = "Value must be a valid phone number.";
        }
      }
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false;
    } else {
      return true;
    }
  };

  const cleanData = (data) => {
    const cleanedData = { ...data };

    Object.keys(cleanedData).forEach((key) => {
      if (cleanedData[key] === "") {
        delete cleanedData[key];
      }
    });

    return cleanedData;
  };

  const insertNewStudent = async () => {
    if (isValidStudent(isData)) {
      setinsertButtonDisabled(true);
      isData.age = +(isData.age);
      isData.admission_date = addmisionDate;
      const cleanedIsData = cleanData(isData);
      const response = await createStudent(cleanedIsData);
      if (response.status === 201) {
        setSuccessMessage("Succesfully Created the Student!");
        setSuccess(true);
        setIsData({
          full_name: "",
          age: "",
          dob: "",
          school: "",
          g_name: "",
          address: "",
          land_phone_number: "",
          phone_number: "",
          whatsapp_number: "",
          g_occupation: "",
          name_of_the_employer: "",
          enrolled_classes: []
        });
        setSelectedGradeForStudent(null);
        setSelectedTeacher(null);
        setSelectedSubject(null);
        setSelectedClasses([]);
        setSelectedSex(null);
        setSelectedSyllabus(null);
        setSelectedMedium(null);
        setSelectedMode(null);
        setBirthDate(new Date());
        fetchData();
        setinsertButtonDisabled(false);
      } else {
        setErrorMessage(response.message);
        setinsertButtonDisabled(false);
        setFailed(true);
      }
    }
  };

  const calculateAge = async (dob) => {
    const birthDate1 = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate1.getFullYear();
    const monthDiff = today.getMonth() - birthDate1.getMonth();
    const dayDiff = today.getDate() - birthDate1.getDate();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    setSelectedStudent((prevData) => ({ ...prevData, age }));
  }

  const handleEditClick = (student) => {
    setSelectedStudent(student);
    calculateAge(student.dob);
    setEditedSelectedSex(student.gender);
    setEditedSelectedSyllabus(student.syllabus);
    setEditedSelectedMedium(student.medium);
    setEditedSelectedMode(student.how_did_you_know_about_ebis);
    setInitialStudent(student);
    setEditmodal(true);
  };

  const isValidEditStudent = (value) => {
    const errors = {};
    if (value.full_name.length < 3) {
      errors.full_name =
        "Student Full Name must be at least 3 characters long.";
    }
    if ((value.phone_number !== undefined && value.phone_number !== null)) {
      if (value.phone_number !== "") {
        if ((value.phone_number.length !== 10)) {
          errors.phone_number = "Value must be a valid phone number.";
        }
      }
    }
    if ((value.land_phone_number !== undefined && value.land_phone_number !== null)) {
      if (value.land_phone_number !== "") {
        if ((value.land_phone_number.length !== 10)) {
          errors.land_phone_number = "Value must be a valid phone number.";
        }
      }
    }
    if ((value.whatsapp_number !== undefined && value.whatsapp_number !== null)) {
      if (value.whatsapp_number !== "") {
        if ((value.whatsapp_number.length !== 10)) {
          errors.whatsapp_number = "Value must be a valid phone number.";
        }
      }
    }
    if (Object.keys(errors).length > 0) {
      setEditError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };

  function getChangedValues(selectedStudent) {
    const changedValues = {};
    for (const key in selectedStudent) {
      if (
        key !== "id" &&
        selectedStudent.hasOwnProperty(key) &&
        selectedStudent[key] !== initialStudent[key]
      ) {
        changedValues[key] = selectedStudent[key];
      }

      if (key === "id") {
        changedValues[key] = selectedStudent[key];
      }
    }
    return changedValues;
  }

  const editStudentDetails = async () => {
    const validityCheck = isValidEditStudent(selectedStudent);
    if (validityCheck === true) {
      setEditedInput(false);
      const data = getChangedValues(selectedStudent);
      selectedStudent.age = +(selectedStudent.age);
      const cleanedData = cleanData(selectedStudent);
      const body = JSON.stringify(cleanedData);
      const response = await editStudent(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        fetchData();
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  const downloadQRCode = async (student) => {
    try {
      const container1 = document.createElement('div');
      container1.style.position = 'absolute';
      container1.style.top = '-9999px';
      document.body.appendChild(container1);
      const root1 = createRoot(container1);

      root1.render(<StudentId student={student} />);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const canvas1 = await html2canvas(container1, {
        scale: 3,
        useCORS: true,
        backgroundColor: null,
      });
      const imgData1 = canvas1.toDataURL('image/jpeg');

      const downloadLink1 = document.createElement('a');
      downloadLink1.href = imgData1;
      downloadLink1.download = `${student.barcode}_ID.jpeg`;
      document.body.appendChild(downloadLink1);
      downloadLink1.click();
      document.body.removeChild(downloadLink1);

      root1.unmount();
      document.body.removeChild(container1);

      const container2 = document.createElement('div');
      container2.style.position = 'absolute';
      container2.style.top = '-9999px';
      document.body.appendChild(container2);
      const root2 = createRoot(container2);

      root2.render(<StudentIdBack student={student} />);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const canvas2 = await html2canvas(container2, {
        scale: 3,
        useCORS: true,
        backgroundColor: null,
      });
      const imgData2 = canvas2.toDataURL('image/jpeg');

      const downloadLink2 = document.createElement('a');
      downloadLink2.href = imgData2;
      downloadLink2.download = `${student.barcode}_ID_second_page.jpeg`;
      document.body.appendChild(downloadLink2);
      downloadLink2.click();
      document.body.removeChild(downloadLink2);

      root2.unmount();
      document.body.removeChild(container2);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const setDateOfBirth = async (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setBirthDate(newDate);
    setIsData((prevData) => ({ ...prevData, dob: newDate }))
  }

  const studentNew = {
    registration_number: 'ST1737904488004',
    full_name: 'Nuwan Kavishka Ganepola',
    image_url: '',
    barcode: '1001001'
  }
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Add New Student</h3>
          </CardHeader>
          {/* <StudentId student={studentNew} /> */}
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="12" lg="12" xl="10">
                <FormGroup>
                  <Input
                    id="fullName"
                    placeholder="Full Name"
                    type="text"
                    value={isData.full_name}
                    onChange={handleInputChange}
                    name="full_name"
                  />
                  {isError.full_name && (
                    <p className="text-danger">{isError.full_name}</p>
                  )}
                </FormGroup>
                <Row>
                  <Col sm="12" md="4" lg="3">
                    <FormGroup>
                      <Input
                        id="age"
                        placeholder="Age"
                        type="number"
                        value={isData.age}
                        onChange={handleInputChange}
                        name="age"
                      />
                      {isError.age && (
                        <p className="text-danger">{isError.age}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="8" lg="5">
                    <FormGroup>
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        showIcon
                        selected={birthDate}
                        onChange={(date) => {
                          setDateOfBirth(date);
                          setIsError({ ...isError, dob: '' })
                        }}
                        className="dateSelector"
                        icon="fa fa-calendar"
                      />

                      {isError.dob && (
                        <p className="text-danger">{isError.dob}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="4">
                    <FormGroup>
                      <Dropdown
                        isOpen={sexDropdownOpen}
                        toggle={() => setSexDropdownOpen(!sexDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedSex
                            ? selectedSex
                            : "Select a Sex"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Sex).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedSex(value),
                                setIsData((prevData) => ({ ...prevData, gender: value.toUpperCase() })),
                                setIsError({ ...isError, sex: '' })
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {isError.sex && (
                        <p className="text-danger">{isError.sex}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    id="school"
                    placeholder="School"
                    type="text"
                    value={isData.school}
                    onChange={handleInputChange}
                    name="school"
                  />
                  {isError.school && (
                    <p className="text-danger">{isError.school}</p>
                  )}
                </FormGroup>
                <Row>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={syllabusDropdownOpen}
                        toggle={() => setSyllabusDropdownOpen(!syllabusDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedSyllabus
                            ? selectedSyllabus
                            : "Select the Syllabus"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Syllabus).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedSyllabus(value),
                                setIsData((prevData) => ({ ...prevData, syllabus: value.toUpperCase() })),
                                setIsError({ ...isError, syllabus: '' })
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {isError.syllabus && (
                        <p className="text-danger">{isError.syllabus}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={mediumDropdownOpen}
                        toggle={() => setMediumDropdownOpen(!mediumDropdownOpen)}
                      >
                        <DropdownToggle caret>
                          {selectedMedium
                            ? selectedMedium
                            : "Select the Medium"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.entries(Medium).map(([key, value]) => (
                            <DropdownItem
                              key={value}
                              value={value}
                              onClick={() => [
                                setSelectedMedium(value),
                                setIsData((prevData) => ({ ...prevData, medium: value.toUpperCase() })),
                                setIsError({ ...isError, medium: '' })
                              ]}
                            >
                              {value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {isError.medium && (
                        <p className="text-danger">{isError.medium}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Dropdown
                    isOpen={modeDropdownOpen}
                    toggle={() => setModeDropdownOpen(!modeDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      {selectedMode
                        ? selectedMode
                        : "How Did You Know About Ebis"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.entries(Modes).map(([key, value]) => (
                        <DropdownItem
                          key={value}
                          value={value}
                          onClick={() => [
                            setSelectedMode(value),
                            setIsData((prevData) => ({ ...prevData, how_did_you_know_about_ebis: value.toUpperCase() })),
                            setIsError({ ...isError, how_did_you_know_about_ebis: '' })
                          ]}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {isError.how_did_you_know_about_ebis && (
                    <p className="text-danger">{isError.how_did_you_know_about_ebis}</p>
                  )}
                </FormGroup>
                {selectedMode !== null && selectedMode === 'FROM_A_STUDENT_OR_PARENT' ?
                  <FormGroup>
                    <Input
                      id="ref_parent_or_student"
                      placeholder="Source of information"
                      type="text"
                      value={isData.ref_parent_or_student || ''}
                      onChange={handleInputChange}
                      name="ref_parent_or_student"
                    />
                  </FormGroup> : null}
                <Label style={{ fontWeight: 'bold' }}>Father / Mother / Guardian Details</Label>
                <FormGroup>
                  <Input
                    id="g_name"
                    placeholder="Name"
                    type="text"
                    value={isData.g_name || ''}
                    onChange={handleInputChange}
                    name="g_name"
                  />
                  {isError.g_name && (
                    <p className="text-danger">{isError.g_name}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="address"
                    placeholder="Address"
                    type="text"
                    value={isData.address || ''}
                    onChange={handleInputChange}
                    name="address"
                  />
                  {isError.address && (
                    <p className="text-danger">{isError.address}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="land_phone_number"
                    placeholder="Land Phone Number"
                    type="text"
                    value={isData.land_phone_number || ''}
                    onChange={handleInputChange}
                    name="land_phone_number"
                  />
                  {isError.land_phone_number && (
                    <p className="text-danger">{isError.land_phone_number}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="phone_number"
                    placeholder="Phone Number"
                    type="text"
                    value={isData.phone_number || ''}
                    onChange={handleInputChange}
                    name="phone_number"
                  />
                  {isError.phone_number && (
                    <p className="text-danger">{isError.phone_number}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="whatsapp_number"
                    placeholder="WhatsApp Number"
                    type="text"
                    value={isData.whatsapp_number || ''}
                    onChange={handleInputChange}
                    name="whatsapp_number"
                  />
                  {isError.whatsapp_number && (
                    <p className="text-danger">{isError.whatsapp_number}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="g_occupation"
                    placeholder="Occupation"
                    type="text"
                    value={isData.g_occupation || ''}
                    onChange={handleInputChange}
                    name="g_occupation"
                  />
                  {isError.g_occupation && (
                    <p className="text-danger">{isError.g_occupation}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="name_of_the_employer"
                    placeholder="Name of the Employer"
                    type="text"
                    value={isData.name_of_the_employer || ''}
                    onChange={handleInputChange}
                    name="name_of_the_employer"
                  />
                  {isError.name_of_the_employer && (
                    <p className="text-danger">{isError.name_of_the_employer}</p>
                  )}
                </FormGroup>
                <Label style={{ fontWeight: 'bold' }}>Admission Date</Label>
                <FormGroup>
                  <DatePicker
                    showIcon
                    selected={addmisionDate}
                    onChange={(date) => {
                      setAddmissionDate(date);
                    }}
                    className="dateSelector"
                    icon="fa fa-calendar"
                  />
                </FormGroup>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={gradeDropdownOpen}
                        toggle={() =>
                          setGradeDropdownOpen(!gradeDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedGradeForStudent ? selectedGradeForStudent.name : "Grade"}
                        </DropdownToggle>
                        {allGrades && allGrades.length > 0 ? (
                          <DropdownMenu>
                            {allGrades.map((grade) => (
                              <DropdownItem
                                key={grade.name}
                                value={grade.id}
                                onClick={() => [
                                  setIsData({
                                    ...isData,
                                    gradeId: grade.id,
                                  }),
                                  setSelectedTeacher(null),
                                  setSelectedSubject(null),
                                  setSelectedGradeForStudent(grade),
                                  getTeachers(grade.id),
                                  setEnrollClsError('')
                                ]}
                              >
                                {grade.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={teacherDropdownOpen}
                        toggle={() =>
                          setTeacherDropdownOpen(!teacherDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedTeacher ? selectedTeacher.full_name : "Teacher"}
                        </DropdownToggle>
                        {allTeachersByGrade && allTeachersByGrade.length > 0 ? (
                          <DropdownMenu>
                            {allTeachersByGrade && allTeachersByGrade.map((teacher) => (
                              <DropdownItem
                                key={teacher.full_name}
                                value={teacher.id}
                                onClick={() => {
                                  setSelectedTeacher(teacher);
                                  setSelectedSubject(null);
                                  getSubjects(selectedGradeForStudent.id, teacher.id);
                                  setTeacherDropdownOpen(false);
                                  setEnrollClsError('')
                                }}
                              >
                                {teacher.full_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={subjectDropdownOpen}
                        toggle={() =>
                          setSubjectDropdownOpen(!subjectDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedSubject ? selectedSubject.name : "Subject"}
                        </DropdownToggle>
                        {allSubjects && allSubjects.length > 0 ? (
                          <DropdownMenu>
                            {allSubjects && allSubjects.map((subject) => (
                              <DropdownItem
                                key={subject.name}
                                value={subject.id}
                                onClick={() => {
                                  setSelectedSubject(subject);
                                  setSubjectDropdownOpen(false);
                                  setEnrollClsError('')
                                }}
                              >
                                {subject.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Button color="primary" style={{ height: "100%" }} onClick={() => addClass()}>✓</Button>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-danger mb-2">{enrollClsError}</div>
                <div>
                  <Table className="align-items-center" responsive>
                    <tbody>
                      {selectedClasses?.map((selectedClass, index) => (
                        <tr key={index}>
                          <td>{selectedClass.teacher}</td>
                          <td>{selectedClass.subject} - {selectedClass.grade}  </td>
                          <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledClass(index)}>X</span></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    insertNewStudent();
                  }}
                  disabled={
                    !isData.full_name ||
                    isInsertButtonDisabled
                  }
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllStudents ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="mb-4">
                <Col>
                  <h3 className="mb-0">Students</h3>
                </Col>
              </Row>
              <Row className="filterClass">
                <Col md="8" lg="4">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <Input
                        type="text"
                        placeholder="Student Name / Barcode"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="4" style={{ paddingLeft: "15px" }}>
                  <FormGroup>
                    <Dropdown
                      isOpen={gradeFilterDropdownOpen}
                      toggle={() => setGradeFilterDropdownOpen(!gradeFilterDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedGradeFilter ? selectedGradeFilter.name : "Grade"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: 250, overflowY: "auto" }}
                      >
                        {allGrades?.map((grade) => (
                          <DropdownItem
                            key={grade.id}
                            value={grade.id}
                            onClick={() => [
                              setSelectedGradeFilter(grade),
                              setSelectedTeacherFilter(null),
                              setSelectedSubjectFilter(null),
                              getTeachersToFilter(grade.id),
                            ]}
                          >
                            {grade.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4" style={{ paddingLeft: "15px" }}>
                  <FormGroup>
                    <Dropdown
                      isOpen={teacherFilterDropdownOpen}
                      toggle={() =>
                        setTeacherFilterDropdownOpen(!teacherFilterDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedTeacherFilter
                          ? selectedTeacherFilter.full_name
                          : "Teacher"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: 250, overflowY: "auto" }}
                      >
                        {allTeachers?.map((teacher) => (
                          <DropdownItem
                            key={teacher.id}
                            value={teacher.id}
                            onClick={() => [
                              setSelectedTeacherFilter(teacher),
                              setSelectedSubjectFilter(null),
                              getSubjectsToFilter(selectedGradeFilter.id, teacher.id)
                            ]
                            }
                          >
                            {teacher.full_name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4" style={{ paddingLeft: "15px" }}>
                  <FormGroup>
                    <Dropdown
                      isOpen={subjectFilterDropdownOpen}
                      toggle={() =>
                        setSubjectFilterDropdownOpen(!subjectFilterDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedSubjectFilter
                          ? selectedSubjectFilter.name
                          : "Subject"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: 250, overflowY: "auto" }}
                      >
                        {allActiveSubjects?.map((subject) => (
                          <DropdownItem
                            key={subject.id}
                            value={subject.id}
                            onClick={() =>
                              setSelectedSubjectFilter(subject)
                            }
                          >
                            {subject.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col className="filterMobile">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => filterStudents(selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id)}
                  >
                    Filter
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Reg. No</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Status</th>
                  <th colSpan="3" className="actionTh">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allStudents?.map((student, index) => (
                  <tr key={index}>
                    <td>{student.registration_number}</td>
                    <td>{student.full_name}</td>
                    <td>{student.barcode}</td>
                    <td>{student.phone_number}</td>
                    <td>{student.status}</td>
                    <td className="actionTd" style={{ textAlign: "center" }}>
                      <Button
                        color="default"
                        type="button"
                        tag={Link}
                        to={`/admin/students/${student.id}`}
                      >
                        Class Details
                      </Button>
                      <Button color="primary"
                        type="button"
                        id="downloadId"
                        onClick={() => downloadQRCode(student)}>
                        <LiaQrcodeSolid />
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        id="editStudent"
                        onClick={() => handleEditClick(student)}
                      >
                        <BiEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        {/* Edit student */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Student
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setEditError({
                  full_name: "",
                  phone_number: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Barcode</Label>
                    <Input
                      placeholder="Barcode"
                      type="text"
                      value={
                        selectedStudent?.barcode
                          ? selectedStudent?.barcode
                          : ""
                      }
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Registration Number</Label>
                    <Input
                      placeholder="Registration Number"
                      type="text"
                      value={
                        selectedStudent?.registration_number
                          ? selectedStudent?.registration_number
                          : ""
                      }
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Full Name</Label>
                    <Input
                      id="editFullName"
                      placeholder="Full Name"
                      type="text"
                      value={
                        selectedStudent?.full_name
                          ? selectedStudent?.full_name
                          : ""
                      }
                      name="full_name"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          full_name: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ full_name: "" });
                      }}
                    />
                    {isEditError.full_name && (
                      <p className="text-danger">{isEditError.full_name}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Age</Label>
                    <Input
                      id="editAge"
                      placeholder="Age"
                      type="text"
                      value={
                        selectedStudent?.age
                          ? selectedStudent?.age
                          : ""
                      }
                      name="age"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          age: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ age: "" });
                      }}
                    />
                    {isEditError.age && (
                      <p className="text-danger">{isEditError.age}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Date of Birth</Label><br />
                    <DatePicker
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      showIcon
                      selected={selectedStudent?.dob ? selectedStudent.dob : ''}
                      onChange={(date) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          dob: date,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ dob: "" });
                      }}
                      className="dateSelector"
                      icon="fa fa-calendar"
                    />

                    {isError.dob && (
                      <p className="text-danger">{isError.dob}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Gender</Label>
                    <Dropdown
                      isOpen={sexEditedDropdownOpen}
                      toggle={() => setEditedSexDropdownOpen(!sexEditedDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedEditedSex
                          ? selectedEditedSex
                          : "Select a Sex"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Sex).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => {
                              setSelectedStudent({
                                ...selectedStudent,
                                gender: value.toUpperCase(),
                              });
                              setEditedInput(true);
                              setEditedSelectedSex(value);
                              setDisabledEditButton(false);
                              setEditError({ gender: "" });
                            }}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {isError.sex && (
                      <p className="text-danger">{isError.sex}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>School</Label>
                    <Input
                      id="editScl"
                      placeholder="School"
                      type="text"
                      value={
                        selectedStudent?.school
                          ? selectedStudent?.school
                          : ""
                      }
                      name="school"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          school: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ school: "" });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Syllabus</Label>
                    <Dropdown
                      isOpen={syllabusEditedDropdownOpen}
                      toggle={() => setEditedSyllabusDropdownOpen(!syllabusEditedDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedEditedSyllabus
                          ? selectedEditedSyllabus
                          : "Select the Syllabus"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Syllabus).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => {
                              setEditedSelectedSyllabus(value);
                              setSelectedStudent({
                                ...selectedStudent,
                                syllabus: value.toUpperCase(),
                              });
                              setEditedInput(true);
                              setDisabledEditButton(false);
                            }}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <FormGroup>
                    <Label>Medium</Label>
                    <Dropdown
                      isOpen={mediumEditedDropdownOpen}
                      toggle={() => setEditedMediumDropdownOpen(!mediumEditedDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedEditedMedium
                          ? selectedEditedMedium
                          : "Select the Medium"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Medium).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => {
                              setEditedSelectedMedium(value);
                              setSelectedStudent({
                                ...selectedStudent,
                                medium: value.toUpperCase(),
                              });
                              setEditedInput(true);
                              setDisabledEditButton(false);
                            }}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <FormGroup>
                    <Label>How did you know about ebis</Label>
                    <Dropdown
                      isOpen={modeEditedDropdownOpen}
                      toggle={() => setEditedModeDropdownOpen(!modeEditedDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedEditedMode
                          ? selectedEditedMode
                          : "How Did You Know About Ebis"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Modes).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => {
                              setEditedSelectedMode(value);
                              setSelectedStudent({
                                ...selectedStudent,
                                how_did_you_know_about_ebis: value.toUpperCase(),
                              });
                              setEditedInput(true);
                              setDisabledEditButton(false);
                            }}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  {selectedEditedMode !== null && selectedEditedMode === 'FROM_A_STUDENT_OR_PARENT' ?
                    <FormGroup>
                      <Input
                        id="ref_parent_or_student"
                        placeholder="Source of information"
                        type="text"
                        value={
                          selectedStudent?.ref_parent_or_student
                            ? selectedStudent?.ref_parent_or_student
                            : ""
                        }
                        name="ref_parent_or_student"
                        onChange={(e) => {
                          setSelectedStudent({
                            ...selectedStudent,
                            ref_parent_or_student: e.target.value,
                          });
                          setEditedInput(true);
                          setDisabledEditButton(false);
                        }}
                      />
                    </FormGroup> : null}
                  <FormGroup>
                    <Label>Father/ Mother / Guardian Details</Label>
                    <Input
                      id="editGName"
                      placeholder="Name"
                      type="text"
                      value={
                        selectedStudent?.g_name
                          ? selectedStudent?.g_name
                          : ""
                      }
                      name="g_name"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          g_name: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Address</Label>
                    <Input
                      id="editAddress"
                      placeholder="Address"
                      type="text"
                      value={
                        selectedStudent?.address
                          ? selectedStudent?.address
                          : ""
                      }
                      name="address"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          address: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Land Phone Number</Label>
                    <Input
                      id="editLNumber"
                      placeholder="Land Phone Number"
                      type="text"
                      value={
                        selectedStudent?.land_phone_number
                          ? selectedStudent?.land_phone_number
                          : ""
                      }
                      name="land_phone_number"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          land_phone_number: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ land_phone_number: "" });
                      }}
                    />
                    {isEditError.land_phone_number && (
                      <p className="text-danger">{isEditError.land_phone_number}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Mobile Phone Number</Label>
                    <Input
                      id="editPhoneNumber"
                      placeholder="Phone Number"
                      type="text"
                      value={
                        selectedStudent?.phone_number
                          ? selectedStudent?.phone_number
                          : ""
                      }
                      name="phone_number"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          phone_number: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ phone_number: "" });
                      }}
                    />
                    {isEditError.phone_number && (
                      <p className="text-danger">{isEditError.phone_number}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>WhatsApp Number</Label>
                    <Input
                      id="editWhatsAppNumber"
                      placeholder="WhatsApp Number"
                      type="text"
                      value={
                        selectedStudent?.whatsapp_number
                          ? selectedStudent?.whatsapp_number
                          : ""
                      }
                      name="whatsapp_number"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          whatsapp_number: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ whatsapp_number: "" });
                      }}
                    />
                    {isEditError.whatsapp_number && (
                      <p className="text-danger">{isEditError.whatsapp_number}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Occupation</Label>
                    <Input
                      id="editOccupation"
                      placeholder="Occupation"
                      type="text"
                      value={
                        selectedStudent?.g_occupation
                          ? selectedStudent?.g_occupation
                          : ""
                      }
                      name="g_occupation"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          g_occupation: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Name of the Employer</Label>
                    <Input
                      id="editEmp"
                      placeholder="Employer"
                      type="text"
                      value={
                        selectedStudent?.name_of_the_employer
                          ? selectedStudent?.name_of_the_employer
                          : ""
                      }
                      name="name_of_the_employer"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          name_of_the_employer: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                  <Label>Admission Date</Label><br/>
                  <DatePicker
                    showIcon
                    selected={selectedStudent?.admission_date || new Date()}
                    onChange={(date) => {
                      setSelectedStudent({
                        ...selectedStudent,
                        admission_date: date,
                      });
                      setEditedInput(true);
                      setDisabledEditButton(false);
                    }}
                    className="dateSelector"
                    icon="fa fa-calendar"
                  />
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      isOpen={statusDropdownOpen}
                      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedStudent
                          ? selectedStudent.status
                          : "Select a Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Status).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => [
                              setSelectedStudent({
                                ...selectedStudent,
                                status: value,
                              }),
                              setEditedInput(true),
                              setDisabledEditButton(false),
                            ]}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>

                  <Button
                    color="primary"
                    type="button"
                    onClick={() => editStudentDetails()}
                    disabled={!isEditedInput || isDisabledEditButton}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div >
    </>
  );
};

export default Students;
